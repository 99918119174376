import {
  fantasyImage,
  fantasyIcon,
  tacticImage,
  tacticIcon,
  triviaSportsImage,
  triviaSportsIcon,
  triviaArtImage,
  triviaArtIcon,
  triviaKataflamImage,
  triviaDerbyImage,
  fantasySabahoKoraImage,
  triviaSportsSabahoKoraImage,
  triviaArtBeta3AflamImage,
  triviaSportsJosFootyImage,
  tacticErza3Image,
  triviaSportsAhly,
  splLogo,
  rsffLogo,
  aymanAbbasImage,
  watchItLogo,
  weLogo,
  sharkTankLogo,
  amrDiabImage,
  amrMansiImage,
  mpmLogo,
  peaceCakeLogo,
  mohamedRamadanImage,
  adLogo,
  boxingImage,
  mmaImage,
  triviaGeneralKnowledgeImage,
  triviaAlixEarleImage,
  triviaCHDImage,
} from './icons';
import type {Game, GameConfig} from './types';

export const games = {
  fantasy: {
    name: 'fantasy',
    type: 'fantasy',
    logo: fantasyIcon,
    image: fantasyImage,
    route: 'fantasy',
  },
  tactic: {
    name: 'tactic',
    type: 'tactic',
    logo: tacticIcon,
    image: tacticImage,
    route: 'tactic',
  },
  'trivia-sports': {
    name: 'trivia-sports',
    type: 'trivia',
    topic: 'sports',
    logo: triviaSportsIcon,
    image: triviaSportsImage,
    route: 'trivia-sports',
  },
  'trivia-art': {
    name: 'trivia-art',
    type: 'trivia',
    topic: 'art',
    logo: triviaArtIcon,
    image: triviaArtImage,
    route: 'trivia-art',
  },
  'trivia-sports-derby': {
    name: 'trivia-sports-derby',
    type: 'trivia',
    topic: 'sports',
    partner: 'derby',
    logo: triviaDerbyImage,
    image: triviaDerbyImage,
    route: 'derby',
  },
  'trivia-art-kataflam': {
    name: 'trivia-art-kataflam',
    type: 'trivia',
    topic: 'art',
    partner: 'kataflam',
    logo: triviaKataflamImage,
    image: triviaKataflamImage,
    route: 'kataflam',
  },
  'fantasy-sabaho-kora': {
    name: 'fantasy-sabaho-kora',
    type: 'fantasy',
    partner: 'sabaho-kora',
    logo: fantasySabahoKoraImage,
    image: fantasySabahoKoraImage,
    route: 'sabaho-kora-fantasy',
  },
  'trivia-sports-sabaho-kora': {
    name: 'trivia-sports-sabaho-kora',
    type: 'trivia',
    topic: 'sports',
    partner: 'sabaho-kora',
    logo: triviaSportsSabahoKoraImage,
    image: triviaSportsSabahoKoraImage,
    route: 'sabaho-kora-trivia',
  },
  'tactic-erza3': {
    name: 'tactic-erza3',
    type: 'tactic',
    partner: 'erza3',
    logo: tacticErza3Image,
    image: tacticErza3Image,
    route: 'erza3',
  },
  'trivia-sports-jos-footy': {
    name: 'trivia-sports-jos-footy',
    type: 'trivia',
    topic: 'sports',
    partner: 'jos-footy',
    logo: triviaSportsJosFootyImage,
    image: triviaSportsJosFootyImage,
    route: 'jos-footy',
  },
  'trivia-art-beta3-aflam': {
    name: 'trivia-art-beta3-aflam',
    type: 'trivia',
    topic: 'art',
    partner: 'beta3-aflam',
    logo: triviaArtBeta3AflamImage,
    image: triviaArtBeta3AflamImage,
    route: 'beta3-aflam',
  },
  'trivia-sports-ahly': {
    name: 'trivia-sports-ahly',
    type: 'trivia',
    topic: 'art',
    partner: 'ahly',
    logo: triviaSportsAhly,
    image: triviaSportsAhly,
    route: 'ahly',
  },
  'trivia-art-rsff': {
    name: 'trivia-art-rsff',
    type: 'trivia',
    topic: 'art',
    partner: 'rsff',
    logo: rsffLogo,
    image: rsffLogo,
    route: 'redseafilmfestival',
  },
  'trivia-sports-spl': {
    name: 'trivia-sports-spl',
    type: 'trivia',
    topic: 'sports',
    partner: 'spl',
    logo: splLogo,
    image: splLogo,
    route: 'trivia-spl',
  },
  'fantasy-spl': {
    name: 'fantasy-spl',
    type: 'fantasy',
    partner: 'spl',
    logo: splLogo,
    image: splLogo,
    route: 'fantasy-spl',
  },
  'tactic-spl': {
    name: 'tactic-spl',
    type: 'tactic',
    partner: 'spl',
    logo: splLogo,
    image: splLogo,
    route: 'tactic-spl',
  },
  'trivia-misc-ayman-abbas': {
    name: 'trivia-misc-ayman-abbas',
    type: 'trivia',
    partner: 'ayman-abbas',
    logo: aymanAbbasImage,
    image: aymanAbbasImage,
    route: 'trivia-ayman-abbas',
    topic: 'misc',
  },
  'trivia-art-watch-it': {
    name: 'trivia-art-watch-it',
    type: 'trivia',
    partner: 'watch-it',
    logo: watchItLogo,
    image: watchItLogo,
    route: 'trivia-watch-it',
    topic: 'art',
  },
  'trivia-misc-we': {
    name: 'trivia-misc-we',
    type: 'trivia',
    partner: 'we',
    logo: weLogo,
    image: weLogo,
    route: 'trivia-we',
    topic: 'misc',
  },
  'trivia-misc-shark-tank': {
    name: 'trivia-misc-shark-tank',
    type: 'trivia',
    partner: 'shark-tank',
    logo: sharkTankLogo,
    image: sharkTankLogo,
    route: 'trivia-shark-tank',
    topic: 'misc',
  },
  'trivia-misc-amr-diab': {
    name: 'trivia-misc-amr-diab',
    type: 'trivia',
    partner: 'amr-diab',
    logo: amrDiabImage,
    image: amrDiabImage,
    route: 'trivia-amr-diab',
    topic: 'misc',
  },
  'trivia-misc-amr-mansi': {
    name: 'trivia-misc-amr-mansi',
    type: 'trivia',
    partner: 'amr-mansi',
    logo: amrMansiImage,
    image: amrMansiImage,
    route: 'trivia-amr-mansi',
    topic: 'misc',
  },
  'trivia-misc-mpm': {
    name: 'trivia-misc-mpm',
    type: 'trivia',
    partner: 'mpm',
    logo: mpmLogo,
    image: mpmLogo,
    route: 'trivia-mpm',
    topic: 'misc',
  },
  'trivia-misc-peace-cake': {
    name: 'trivia-misc-peace-cake',
    type: 'trivia',
    partner: 'peace-cake',
    logo: peaceCakeLogo,
    image: peaceCakeLogo,
    route: 'trivia-peace-cake',
    topic: 'misc',
  },
  'trivia-art-mohamed-ramadan': {
    name: 'trivia-art-mohamed-ramadan',
    type: 'trivia',
    partner: 'mohamed-ramadan',
    logo: mohamedRamadanImage,
    image: mohamedRamadanImage,
    route: 'trivia-mohamed-ramadan',
    topic: 'art',
  },
  'trivia-sports-boxing': {
    name: 'trivia-sports-boxing',
    type: 'trivia',
    logo: boxingImage,
    image: boxingImage,
    route: 'trivia-boxing',
    topic: 'sports',
  },
  'fantasy-ad': {
    name: 'fantasy-ad',
    type: 'fantasy',
    logo: adLogo,
    image: adLogo,
    route: 'fantasy-ad',
  },
  'trivia-sports-mma': {
    name: 'trivia-sports-mma',
    type: 'trivia',
    logo: mmaImage,
    image: mmaImage,
    route: 'trivia-mma',
    topic: 'sports',
  },
  'trivia-misc-general': {
    name: 'trivia-misc-general',
    type: 'trivia',
    logo: triviaGeneralKnowledgeImage,
    image: triviaGeneralKnowledgeImage,
    route: 'trivia-general-knowledge',
    topic: 'misc',
  },
  'tactic-boxing': {
    name: 'tactic-boxing',
    type: 'tactic',
    logo: boxingImage,
    image: boxingImage,
    route: 'tactic-boxing',
  },
  'tactic-mma': {
    name: 'tactic-mma',
    type: 'tactic',
    logo: mmaImage,
    image: mmaImage,
    route: 'tactic-mma',
  },
  'trivia-misc-alix-earle': {
    name: 'trivia-misc-alix-earle',
    type: 'trivia',
    logo: triviaAlixEarleImage,
    image: triviaAlixEarleImage,
    route: 'trivia-alix-earle',
    topic: 'misc',
  },
  'trivia-misc-chd': {
    name: 'trivia-misc-chd',
    type: 'trivia',
    logo: triviaCHDImage,
    image: triviaCHDImage,
    route: 'trivia-chd',
    topic: 'misc',
  },
} as const satisfies Record<Game, GameConfig>;
