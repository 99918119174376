export {default as headerDecoration} from './header-decoration.png';
export {default as fantasyImage} from './fantasy-image.webp';
export {default as fantasyIcon} from './fantasy-icon.png';
export {default as tacticImage} from './tactic-image.webp';
export {default as tacticIcon} from './tactic-icon.png';
export {default as triviaSportsImage} from './trivia-sports-image.png';
export {default as triviaSportsIcon} from './trivia-sports-icon.png';
export {default as triviaArtImage} from './trivia-art-image.webp';
export {default as triviaArtIcon} from './trivia-art-icon.png';
export {default as triviaDerbyImage} from './derby.png';
export {default as triviaKataflamImage} from './kataflam.webp';
export {default as fantasySabahoKoraImage} from './fantasy-sabaho-kora-image.png';
export {default as triviaSportsSabahoKoraImage} from './trivia-sabaho-kora-image.png';
export {default as tacticErza3Image} from './tactic-erza3-image.jpeg';
export {default as triviaSportsJosFootyImage} from './trivia-sports-jos-footy-image.jpeg';
export {default as triviaArtBeta3AflamImage} from './trivia-art-beta3-aflam-image.jpeg';
export {default as triviaSportsAhly} from './trivia-sports-ahly-logo.png';
export {default as splLogo} from './spl-logo.png';
export {default as rsffLogo} from './rsff-logo.png';
export {default as aymanAbbasImage} from './trivia-ayman-abbas-image.webp';
export {default as watchItLogo} from './watch-it-logo.png';
export {default as weLogo} from './we-logo.png';
export {default as sharkTankLogo} from './shark-tank-logo.png';
export {default as amrDiabImage} from './amr-diab-image.jpeg';
export {default as amrMansiImage} from './amr-mansi-image.webp';
export {default as mpmLogo} from './mpm-logo.svg';
export {default as peaceCakeLogo} from './peace-cake-logo.png';
export {default as mohamedRamadanImage} from './mohamed-ramadan-image.png';
export {default as adLogo} from './ad-logo.jpeg';
export {default as boxingImage} from './boxing-image.png';
export {default as mmaImage} from './mma-image.png';
export {default as triviaGeneralKnowledgeImage} from './trivia-general-knowledge-image.png';
export {default as triviaAlixEarleImage} from './alix-earle-image.png';
export {default as triviaCHDImage} from './chd-image.png';
